import { NavLink } from 'react-router-dom';
import SvgIcon from 'components/SvgIcon';
import { ROUTES } from 'app/routes';
import './AppNavbar.scss';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'features/auth';
import { AccountTypes } from 'features/account-setup/types';
import NavCollapse from 'components/NavCollapse';
import ShowForRoles from 'components/ShowForRoles';
import { Roles } from 'features/types';

type Props = {
  sidebarOpen: boolean;
  closeSidebar(): void;
};

const AppNavbar = ({ sidebarOpen, closeSidebar }: Props) => {
  const user = useAppSelector(selectUser);

  return (
    <div className={`app-sidebar ${sidebarOpen ? 'opened' : ''}`}>
      <nav className="sidebar-nav">
        <ul>
          {user?.activeAccountType === AccountTypes.Organization && (
            <>
              <li>
                <NavCollapse routes={[ROUTES.PROGRAM_PAGE, ROUTES.PROGRAM_NETWORKS_PAGE]} name="Groups" svgIcon="sidebar/document">
                  <>
                    <NavLink className="nav-collapse-link" to={ROUTES.PROGRAM_PAGE} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Groups</span>
                    </NavLink>
                    <NavLink className="nav-collapse-link" to={ROUTES.PROGRAM_NETWORKS_PAGE} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Group networks</span>
                    </NavLink>
                  </>
                </NavCollapse>
              </li>
              <li>
                <NavLink exact to={ROUTES.JOB_SEEKERS} onClick={() => closeSidebar()} >
                  <span className="menu-link-title"><SvgIcon name="sidebar/job-seekers" />Job seekers</span>
                </NavLink>
              </li>
              <li>
                <NavCollapse routes={[ROUTES.OPPORTUNITIES, ROUTES.OPPORTUNITIES_ADDED_INTERNALLY, ROUTES.JOB_RESPONSES_BY_ORGANIZATION]} name="Jobs" svgIcon="sidebar/job-templates">
                  <>
                    <NavLink className="nav-collapse-link" to={ROUTES.OPPORTUNITIES} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">All jobs</span>
                    </NavLink>
                    <NavLink className="nav-collapse-link" to={ROUTES.OPPORTUNITIES_ADDED_INTERNALLY} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Added internally</span>
                    </NavLink>
                    <NavLink className="nav-collapse-link" to={ROUTES.JOB_RESPONSES_BY_ORGANIZATION} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Applications</span>
                    </NavLink>
                  </>
                </NavCollapse>
              </li>
              <li>
                <NavLink exact to={ROUTES.DISCOVER__EMPLOYERS} onClick={() => closeSidebar()} >
                  <span className="menu-link-title"><SvgIcon name="sidebar/briefcase" />Employers</span>
                </NavLink>
              </li>
              <li>
                <NavCollapse routes={[ROUTES.RESOURCES, ROUTES.DISCOVER_RESOURCES_BY_ORGANIZATION]} name="Resources" svgIcon="sidebar/resources">
                  <>
                    <NavLink className="nav-collapse-link" to={ROUTES.RESOURCES} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Resources</span>
                    </NavLink>
                    <NavLink className="nav-collapse-link" to={ROUTES.DISCOVER_RESOURCES_BY_ORGANIZATION} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Discover resources</span>
                    </NavLink>
                  </>
                </NavCollapse>
              </li>
            </>
          )}
          {user?.activeAccountType === AccountTypes.Employer && (
            <>
              <li>
                <NavCollapse routes={[ROUTES.JOB_POSTS, ROUTES.JOB_RESPONSES_BY_EMPLOYER]} name="Jobs" svgIcon="sidebar/job-templates">
                  <>
                    <NavLink className="nav-collapse-link" to={ROUTES.JOB_POSTS} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Jobs</span>
                    </NavLink>
                    <NavLink className="nav-collapse-link" to={ROUTES.JOB_RESPONSES_BY_EMPLOYER} onClick={() => closeSidebar()} >
                      <span className="menu-link-title">Applications</span>
                    </NavLink>
                  </>
                </NavCollapse>
              </li>
              {!user.employer?.isProgramsHidden && (
                <li>
                  <NavCollapse routes={[ROUTES.EXPLORE_PROGRAMS, ROUTES.EXPLORE_PROGRAM_NETWORKS]} name="Groups" svgIcon="sidebar/document">
                    <>
                      <NavLink className="nav-collapse-link" to={ROUTES.EXPLORE_PROGRAMS} onClick={() => closeSidebar()} >
                        <span className="menu-link-title">Groups</span>
                      </NavLink>
                      <NavLink className="nav-collapse-link" to={ROUTES.EXPLORE_PROGRAM_NETWORKS} onClick={() => closeSidebar()} >
                        <span className="menu-link-title">Group networks</span>
                      </NavLink>
                    </>
                  </NavCollapse>
                </li>
              )}
            </>
          )}
          <ShowForRoles roles={[Roles.Owner]}>
            <li>
              <NavCollapse routes={[ROUTES.DETAILS, ROUTES.MANAGERS_SETTINGS, ROUTES.PUBLIC_JOB_BOARD_SETTINGS]} name="Account settings" svgIcon="sidebar/settings">
                <>
                  <NavLink className="nav-collapse-link" to={ROUTES.DETAILS} onClick={closeSidebar}>
                    <span className="menu-link-title">Details</span>
                  </NavLink>
                  <NavLink className="nav-collapse-link" to={ROUTES.MANAGERS_SETTINGS} onClick={closeSidebar}>
                    <span className="menu-link-title">Managers</span>
                  </NavLink>
                  {user?.accountTypes.includes(AccountTypes.Organization) && <NavLink className="nav-collapse-link" to={ROUTES.PUBLIC_JOB_BOARD_SETTINGS} onClick={closeSidebar}>
                    <span className="menu-link-title">Public job board</span>
                  </NavLink>}
                </>
              </NavCollapse>
            </li>
          </ShowForRoles>
          <ShowForRoles roles={[Roles.ProgramManager]}>
            <li>
              <NavCollapse routes={[ROUTES.MANAGERS_SETTINGS]} name="Account settings" svgIcon="sidebar/settings">
                <>
                  <NavLink className="nav-collapse-link" to={ROUTES.MANAGERS_SETTINGS} onClick={closeSidebar}>
                    <span className="menu-link-title">Managers</span>
                  </NavLink>
                </>
              </NavCollapse>
            </li>
          </ShowForRoles>
        </ul>
      </nav>
    </div>
  );
};

export default AppNavbar;
